@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');

@font-face {
  font-family: 'Kapra Neue Pro';
  src: url(./KapraNeueProMediumRd.woff2) format('woff2'),
       url(./KapraNeueProMediumRd.woff) format('woff');
}

@font-face {
    font-family: 'Pitch Sans Bold';
    src: url(./PitchSansWeb-Bold.woff2) format('woff2'),
         url(./PitchSansWeb-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Pitch Sans Medium';
    src: url(./PitchSansWeb-Medium.woff2) format('woff2'),
         url(./PitchSansWeb-Medium.woff) format('woff');
}