body {
  margin: 0;
  font-family: 'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  line-height: 16px !important;
  letter-spacing: 0.61px !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.7rem !important;
  font-weight: 400 !important;
  font-family: 'Rubik' !important;
}